
import { useI18n } from "vue-i18n/index";
import axios from "axios";
import ApiRoutes from "@/core/config/ApiRoutes";
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";

export default defineComponent({
    name: "referencials-warehouses-search-modal",
    emit: ["refreshData"],
    props: {
        params: null,
        getParams: Function
    },
    components: {
        ErrorMessage,
        Field,
        Form,
    },
    setup(props, { emit }) {
        const { t, te } = useI18n();

        let types = ref([]);
        let infrastructuresL1 = ref([]);
        let infrastructuresL2 = ref([]);
        let infrastructuresL3 = ref([]);
        let infrastructures = ref([]);

        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const searchWarehouseModalRef = ref<null | HTMLElement>(null);
        const initialValues = {
            name: "",
            type_id: null,
            infrastructureL1Id: null,
            infrastructureL2Id: null,
            infrastructureL3Id: null,
            infrastructure_id: null,
            description: "",
        };
        const queryParams = ref<any>(props.params);
        Object.assign(queryParams.value, initialValues);

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const getTypes = async () => {
            let response = await axios.get(ApiRoutes.warehouses.types);

            types.value = response.data;
        };

        const getInfrastructures = async (level: number = 1, defaultValue = null) => {

            let params = { level : level, parent_id : null };

            switch (level) {
                case 2:
                    params.parent_id = queryParams.value.infrastructureL1Id;
                    break;

                case 3:
                    params.parent_id = queryParams.value.infrastructureL2Id;
                    break;

                case 4:
                    params.parent_id = queryParams.value.infrastructureL3Id;
                    break;
            }

            const response = await axios.get(ApiRoutes.infrastructures.list, { params: params });

            switch (level) {

                case 2:

                    if (queryParams.value.infrastructureL1Id == '') {
                        queryParams.value.infrastructureL1Id = null;
                        infrastructuresL2.value = [];
                    } else {
                        infrastructuresL2.value = response.data;
                    }

                    queryParams.value.infrastructureL2Id = (defaultValue) ? defaultValue : initialValues.infrastructureL2Id;

                    queryParams.value.categoryL3 = initialValues.infrastructureL3Id;
                    infrastructuresL3.value = [];
                    queryParams.value.infrastructure_id = initialValues.infrastructure_id;
                    infrastructures.value = [];
                    break;

                case 3:

                    if (queryParams.value.infrastructureL2Id == '') {
                        queryParams.value.infrastructureL2Id = null;
                        infrastructuresL3.value = [];
                    } else {
                        infrastructuresL3.value = response.data;
                    }

                    queryParams.value.infrastructureL3Id = (defaultValue) ? defaultValue : initialValues.infrastructureL3Id;

                    queryParams.value.infrastructure_id = initialValues.infrastructure_id;
                    infrastructures.value = [];
                    break;

                case 4:

                    if (queryParams.value.infrastructureL3Id == '') {
                        queryParams.value.infrastructureL3Id = null;
                        infrastructures.value = [];
                    } else {
                        infrastructures.value = response.data;
                    }

                    queryParams.value.infrastructure_id = (defaultValue) ? defaultValue : initialValues.infrastructure_id;

                    break;

                default:
                    queryParams.value.infrastructureL1Id = (defaultValue) ? defaultValue : initialValues.infrastructureL1Id;
                    infrastructuresL1.value = response.data;

                    queryParams.value.categoryL2 = initialValues.infrastructureL2Id;
                    infrastructuresL2.value = [];
                    queryParams.value.categoryL3 = initialValues.infrastructureL3Id;
                    infrastructuresL3.value = [];
                    queryParams.value.categoryL4 = initialValues.infrastructure_id;
                    infrastructures.value = [];
                    break;
            }
        };

        const submit = (values, actions) => {

            if (!submitButtonRef.value) {
                return;
            }

            if (props.getParams) props.getParams(queryParams.value);

            hideModal(searchWarehouseModalRef.value);
        };

        onMounted(() => {
            getTypes();
            getInfrastructures();

            const modal = document.getElementById('kt_modal_search_warehouse');

            // if (modal) modal.addEventListener('hidden.bs.modal', function() {
            // });

            if (modal) modal.addEventListener('shown.bs.modal', function() {

                if (props.params) {
                    // Fill Intra fields
                    Object.assign(queryParams.value, props.params);
                }
            });
        });

        return {
            translate,
            types,
            infrastructuresL1,
            infrastructuresL2,
            infrastructuresL3,
            infrastructures,
            getInfrastructures,
            queryParams,
            submit,
            submitButtonRef,
            searchWarehouseModalRef,
        };
    },
});
