
import { useI18n } from "vue-i18n/index";
import * as Yup from "yup";
import axios, { AxiosResponse } from "axios";
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import ApiRoutes from "@/core/config/ApiRoutes";

export default defineComponent({
    name: "referencials-warehouses-edit-modal",
    emit: ["refreshData"],
    props: {
        warehouse: null
    },
    components: {
        ErrorMessage,
        Field,
        Form,
    },
    setup(props, { emit }) {
        const { t, te } = useI18n();
        let isUpdate = false as boolean;

        let types = ref([]);
        let infrastructuresL1 = ref([]);
        let infrastructuresL2 = ref([]);
        let infrastructuresL3 = ref([]);
        let infrastructures = ref([]);

        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const editWarehouseModalRef = ref<null | HTMLElement>(null);
        const warehouseDefault = {
            id: null,
            name: "",
            type_id: null,
            infrastructureL1Id: null,
            infrastructureL2Id: null,
            infrastructureL3Id: null,
            infrastructure_id: null,
            description: "",
        };
        const warehouseData = ref<any>({});
        Object.assign(warehouseData.value, warehouseDefault);

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const validationSchema = Yup.object().shape({
            name: Yup.string().required().label(translate("warehouseNameLabel")),
        });

        const getTypes = async () => {
            let response = await axios.get(ApiRoutes.warehouses.types);

            types.value = response.data;
        };

        const getInfrastructures = async (level: number = 1, defaultValue = null) => {

            let params = { level : level, parent_id : null };

            switch (level) {
                case 2:
                    params.parent_id = warehouseData.value.infrastructureL1Id;
                    break;

                case 3:
                    params.parent_id = warehouseData.value.infrastructureL2Id;
                    break;

                case 4:
                    params.parent_id = warehouseData.value.infrastructureL3Id;
                    break;
            }

            const response = await axios.get(ApiRoutes.infrastructures.list, { params: params });

            switch (level) {

                case 2:

                    if (warehouseData.value.infrastructureL1Id == '') {
                        warehouseData.value.infrastructureL1Id = null;
                        infrastructuresL2.value = [];
                    } else {
                        infrastructuresL2.value = response.data;
                    }

                    warehouseData.value.infrastructureL2Id = (defaultValue) ? defaultValue : warehouseDefault.infrastructureL2Id;

                    warehouseData.value.categoryL3 = warehouseDefault.infrastructureL3Id;
                    infrastructuresL3.value = [];
                    warehouseData.value.infrastructure_id = warehouseDefault.infrastructure_id;
                    infrastructures.value = [];
                    break;

                case 3:

                    if (warehouseData.value.infrastructureL2Id == '') {
                        warehouseData.value.infrastructureL2Id = null;
                        infrastructuresL3.value = [];
                    } else {
                        infrastructuresL3.value = response.data;
                    }

                    warehouseData.value.infrastructureL3Id = (defaultValue) ? defaultValue : warehouseDefault.infrastructureL3Id;

                    warehouseData.value.infrastructure_id = warehouseDefault.infrastructure_id;
                    infrastructures.value = [];
                    break;

                case 4:

                    if (warehouseData.value.infrastructureL3Id == '') {
                        warehouseData.value.infrastructureL3Id = null;
                        infrastructures.value = [];
                    } else {
                        infrastructures.value = response.data;
                    }

                    warehouseData.value.infrastructure_id = (defaultValue) ? defaultValue : warehouseDefault.infrastructure_id;

                    break;

                default:
                    warehouseData.value.infrastructureL1Id = (defaultValue) ? defaultValue : warehouseDefault.infrastructureL1Id;
                    infrastructuresL1.value = response.data;

                    warehouseData.value.categoryL2 = warehouseDefault.infrastructureL2Id;
                    infrastructuresL2.value = [];
                    warehouseData.value.categoryL3 = warehouseDefault.infrastructureL3Id;
                    infrastructuresL3.value = [];
                    warehouseData.value.categoryL4 = warehouseDefault.infrastructure_id;
                    infrastructures.value = [];
                    break;
            }
        };

        const enableSubmitButton = () => {

            if (submitButtonRef.value) {
                submitButtonRef.value.disabled = false;

                submitButtonRef.value?.removeAttribute("data-kt-indicator");
            }
        };

        const disableSubmitButton = () => {
            if (!submitButtonRef.value) {
                return;
            }

            // Disable button
            submitButtonRef.value.disabled = true;
            // Activate indicator
            submitButtonRef.value.setAttribute("data-kt-indicator", "on");
        };

        const submit = (values, actions) => {
            disableSubmitButton();

            if (isUpdate && props.warehouse.id) warehouseData.value.id = props.warehouse.id;

            axios.post(ApiRoutes.warehouses.updateOrCreate, warehouseData.value)
                .then((response: AxiosResponse) => {

                    enableSubmitButton();

                    if (response.data && response.data.success) {

                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            // actions.resetForm();

                            hideModal(editWarehouseModalRef.value);

                            emit("refreshData");
                        });

                    } else if (response.data && response.data.error) {

                        let error = (Array.isArray(response.data.error)) ? response.data.error.join('</li><li>') : response.data.error;

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><div class="alert alert-danger"><ul class="list-unstyled mb-0"><li>' + error + "</li></ul></div>",
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    enableSubmitButton();

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        onMounted(() => {
            getTypes();

            const modal = document.getElementById('kt_modal_edit_warehouse');

            if (modal) modal.addEventListener('hidden.bs.modal', function(event) {
                infrastructuresL2.value = [];
                infrastructuresL3.value = [];
                infrastructures.value = [];
                Object.assign(warehouseData.value, warehouseDefault);
            });

            if (modal) modal.addEventListener('shown.bs.modal', async function() {
                isUpdate = false;

                if (props.warehouse) {
                    isUpdate = true;

                    disableSubmitButton();

                    // Fill warehouse fields
                    Object.keys(props.warehouse).forEach((key) => {
                        if (warehouseDefault.hasOwnProperty(key)) warehouseData.value[key] = props.warehouse[key];
                    });

                    getInfrastructures(1, props.warehouse.infrastructureL1Id);

                    if (props.warehouse.infrastructureL1Id) {
                        await getInfrastructures(2, props.warehouse.infrastructureL2Id);
                    }

                    if (props.warehouse.infrastructureL2Id) {
                        await getInfrastructures(3, props.warehouse.infrastructureL3Id);
                    }

                    if (props.warehouse.infrastructureL3Id) {
                        await getInfrastructures(4, props.warehouse.infrastructure_id);
                    }

                    enableSubmitButton();

                } else {
                    getInfrastructures();
                }
            });
        });

        return {
            translate,
            types,
            infrastructuresL1,
            infrastructuresL2,
            infrastructuresL3,
            infrastructures,
            getInfrastructures,
            warehouseData,
            validationSchema,
            submit,
            submitButtonRef,
            editWarehouseModalRef,
        };
    },
});
