
import { useI18n } from "vue-i18n/index";
import axios, { AxiosResponse } from "axios";
import { defineComponent, onMounted, ref } from "vue";
import { Modal } from "bootstrap";
import ApiRoutes from "@/core/config/ApiRoutes";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import EditWarehouseModal from "@/components/modals/referencials/warehouses/EditWarehouseModal.vue";
import SearchWarehouseModal from "@/components/modals/referencials/warehouses/SearchWarehouseModal.vue";

interface IPagination {
    page  : number;
    search: boolean;
    exists: number;
    total : number;
    offset: number;
}

export default defineComponent({
    name: "referencials-warehouses",
    props: {
        widgetClasses: String,
    },
    components: {
        EditWarehouseModal,
        SearchWarehouseModal,
    },
    setup() {
        const { t, te } = useI18n();
        let loading = ref(false);
        let activeWarehouse = ref(null);
        const warehouses = ref([]);
        const queryParamsDefault = {
            name : "",
            infrastructure_id : null,
            type_id : null,
            description : "",
        };
        const queryParams = ref({});
        Object.assign(queryParams.value, queryParamsDefault);

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const queryParamsLabels = {
            name : translate("warehouseNameLabel"),
            infrastructure_id : translate("warehouseInfrastructureIdLabel"),
            type_id : translate("warehouseTypeIdLabel"),
            description : translate("warehouseDescriptionLabel"),
        };
        const pagination = ref<IPagination>({
            page  : 1,
            search: false,
            exists: 0,
            total : 0,
            offset: 10,
        });

        const goPage = (page: number) => {
            pagination.value.page = page;

            getWarehouses();
        };

        const setOffset = (event) => {
            pagination.value.page = 1;
            pagination.value.offset = parseInt(event.target.value);

            getWarehouses();
        };

        const refresh = () => {
            pagination.value.page = 1;
            pagination.value.total = 0;
            pagination.value.offset = 10;

            getWarehouses();
        };

        const create = () => {
            activeWarehouse.value = null;
            const modal = new Modal(document.getElementById('kt_modal_edit_warehouse'));
            modal.show();
        };

        const edit = (warehouse) => {
            activeWarehouse.value = warehouse;
            const modal = new Modal(document.getElementById('kt_modal_edit_warehouse'));
            modal.show();
        };

        const confirmRemove = (warehouse_id: number) => {

            Swal.fire({
                title: translate("warningHeading"),
                text: translate("warehouseConfirmDelete"),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: translate("confirm"),
                cancelButtonText: translate("cancel"),
                customClass: {
                    confirmButton: "btn btn-danger",
                    cancelButton: "btn btn-secondary",
                },
            }).then((result) => {

                if (result.isConfirmed) {
                    remove(warehouse_id);
                }
            })
        };

        const remove = (warehouse_id: number) => {

            axios.delete(`${ApiRoutes.warehouses.delete}/${warehouse_id}`)
                .then((response: AxiosResponse) => {

                    if (response.data.success) {

                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            refresh();
                        });
                    } else {

                        Swal.fire({
                            text: response.data.message,
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        const removeQueryParam = (name: string): void => {
            if (queryParams.value && typeof queryParams.value[name] !== 'undefined') {
                queryParams.value[name] = queryParamsDefault[name];

                refresh();
            }
        };

        const getWarehouses = async () => {
            loading.value = true;

            const params = {};
            Object.assign(params, queryParams.value, pagination.value);

            await axios.get(ApiRoutes.warehouses.index, { params: params })
                .then((response: AxiosResponse) => {

                    loading.value = false;

                    pagination.value.search = response.data.search;
                    pagination.value.exists = response.data.exists;
                    pagination.value.total = response.data.total;

                    warehouses.value = response.data.rows;
                })
                .catch((error) => {

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        const searchWarehouses = (params) => {
            Object.assign(queryParams.value, params);

            refresh();
        };

        onMounted(() => {
            getWarehouses();

            setCurrentPageBreadcrumbs(translate("warehouseManagementHeading"), [
                translate("masterDataHeading"),
                translate("referencialsHeading"),
                translate("stockManagementHeading")
            ]);
        });

        return {
            translate,
            warehouses,
            pagination,
            activeWarehouse,
            loading,
            refresh,
            goPage,
            create,
            queryParams,
            queryParamsDefault,
            queryParamsLabels,
            removeQueryParam,
            searchWarehouses,
            edit,
            confirmRemove,
            setOffset,
        };
    },
});
